.app {
  background-color: #282c34;
  text-align: center;
  min-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: white;
}

header {
  width: 100%;
  background-color: #34425c;
  align-self: flex-start;
}

main.main {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  color: white;
  width: 100%;
}

input[type="text"] {
  border-radius: 5px;
  border: none;
  background-color: #323232;
  resize: none;
  
  color: white;
  font: inherit;
  text-align: center;
  width: 75vw;
  height: calc(1rem + 10px);

  margin: .2rem;
}

input[type="text"]:focus, input[type="text"]::placeholder {
  color: white;
  font: inherit;
  text-align: center;
  width: 75vw;
  height: calc(1rem + 10px);
  text-overflow: ellipsis;
}

button {
  border-radius: 5px;
  box-shadow: none;
  border: none;
  background-color: #34425c;
  color: rgba(255,255,255, .7);
  margin: 1rem;
  padding: .5rem;
}


footer {
  width: 100%;
  background-color: #323232;
  align-self: flex-end;
}